






















































import { Component, Vue } from 'vue-property-decorator'
import { checkIn, get } from '@/services/open-checkin-client'
import PollLog from '@/components/PollLog.vue'
import MessageLog from '@/components/MessageLog.vue'
import { getCoursename } from '@/services/open-course-client'

@Component({
  components: { MessageLog, PollLog }
})
export default class Checkin extends Vue {
  private lastname = ''
  private firstname = ''
  private checkedIn = false
  private coursename = ''

  mounted(): void {
    const token = localStorage.getItem('usertoken')

    if (!token) {
      return
    }

    if (this.courseID !== localStorage.getItem('courseID')) {
      return
    }

    get(localStorage.getItem('usertoken')).then(res => {
      const record = res.data

      if (!record) {
        console.log('localStorage token not found!!!! -> Throw exception in backend')
        return
      }

      this.firstname = record.firstname
      this.lastname = record.lastname

      if (record.checkIn && !record.checkOut) {
        this.checkedIn = true
      }
    })

    getCoursename(this.courseID)
      .then(res => {
        this.coursename = res.data
      })
  }

  get courseID(): string {
    return this.$route.params.courseID
  }

  private checkIn(): void {
    checkIn(this.courseID, this.firstname, this.lastname).then(res => {
      const token = res.data
      console.log(`token: ${token}`)
      localStorage.setItem('usertoken', token)
      localStorage.setItem('courseID', this.courseID)
      this.checkedIn = true
    })
  }

  private checkout(): void {
    this.$router.push('checkout')
  }
}
