





























import Vue from 'vue'
import { http } from '@/services/http-api'

export default Vue.extend({
  name: 'App',

  components: {},

  created: function () {
    const showUnavailable = (value: boolean) => {
      this.showSystemUnavailable = value
    }

    const responseInterceptor = (response) => {
      showUnavailable(false)
      return response
    }

    const errorInterceptor = (error) => {
      console.log(error)
      showUnavailable(true) // TODO: maybe exclude logging and other non-essential stuff.
    }

    http.interceptors.response.use(responseInterceptor, errorInterceptor)
  },

  data: () => ({
    showSystemUnavailable: false
  })
})
