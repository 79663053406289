import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Checkin from '@/views/Checkin.vue'
import Checkout from '@/views/Checkout.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    // TODO read from localStorage
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/:courseID',
    name: 'Checkin',
    component: Checkin
  }
  /*
  {
    // TODO: send link via Email/SMS, read from param
    path: '/checkout/:token',
    name: 'Checkout',
    component: CheckoutWithToken
  }
   */
]

const router = new VueRouter({
  routes
})

export default router
