

















import { Component, Prop, Vue } from 'vue-property-decorator'
import { LiveMessage } from '@/components/model'
import PollItem from '@/components/PollItem.vue'
import { formatDistanceToNowGerman } from '@/components/datefns-util'
import { getMessages } from '@/services/open-live-client'

@Component({
  components: { PollItem }
})
export default class MessageLog extends Vue {
  @Prop()
  courseid: string

  private messages: LiveMessage[] = []

  mounted() {
    this.refreshFeed()
    setInterval(this.refreshFeed, 5000)
  }

  private formatTimestamp(timestamp: string) {
    return formatDistanceToNowGerman(new Date(timestamp))
  }

  // TODO: put usertoken to app state
  private refreshFeed() {
    getMessages(this.courseid, localStorage.getItem('usertoken')).then(res => {
      // NOTE: always polling everything also refreshes the timestamp
      this.messages = res.data
    })
  }
}
