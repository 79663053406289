



































import { Component, Prop, Vue } from 'vue-property-decorator'
import { PollDTO } from '@/components/model'
import { sendPollResponse } from '@/services/open-poll-client'

@Component({
  components: {}
})
export default class PollItem extends Vue {
  @Prop()
  poll!: PollDTO

  private response = ''

  private responseSubmitted = false

  get valid(): boolean {
    return !!this.response
  }

  private send() {
    // TODO: validate not empty
    sendPollResponse(this.poll.pollId, localStorage.getItem('usertoken'), this.response).then(() => {
      this.responseSubmitted = true
    })
  }
}
