import { http } from '@/services/http-api'
import { AxiosResponse } from 'axios'
import { CheckinRecord } from '@/components/model'

export const checkIn = (courseID: string,
                        firstname: string,
                        lastname: string): Promise<AxiosResponse<string>> =>
  http.post('/open/checkin/' + courseID, {
    firstname,
    lastname
  })

export const get = (token: string): Promise<AxiosResponse<CheckinRecord>> =>
  http.get('/open/checkin/' + token)

export const checkOut = (token: string): Promise<AxiosResponse<void>> =>
  http.post('/open/checkout/' + token)
