import { formatDistanceToNow } from 'date-fns'

export function formatDistanceToNowGerman(date: Date): string {
  return formatDistanceToNow(date)
    .replace('less than a minute', 'jetzt')
    .replace('about ', '')
    .replace('over ', '')
    .replace('almost ', '')
    .replace('minutes', 'm')
    .replace('minute', 'm')
    .replace('hours', 'h')
    .replace('hour', 'h')
    .replace('days', 'T')
    .replace('day', 'T')
    .replace('months', 'M')
    .replace('month', 'M')
    .replace('years', 'J')
    .replace('year', 'J')
    .replace(' ', '')
}
