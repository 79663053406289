
















import { Component, Vue } from 'vue-property-decorator'
import { checkOut } from '@/services/open-checkin-client'

@Component({
  components: {}
})
export default class Checkout extends Vue {
  private success = false
  private noToken = false

  mounted(): void {
    const token = localStorage.getItem('usertoken')

    if (!token) {
      this.noToken = true
      return
    }

    checkOut(token).then(() => {
      this.success = true
    })
  }
}
