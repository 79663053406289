import { http } from '@/services/http-api'
import { AxiosResponse } from 'axios'
import { PollDTO } from '@/components/model'

export const getPollRequests = (courseID: string, usertoken: string): Promise<AxiosResponse<PollDTO[]>> =>
  http.get(`/open/poll/${courseID}/${usertoken}`)

export const sendPollResponse = (pollId: string,
                                 usertoken: string,
                                 response: string): Promise<AxiosResponse<void>> =>
  http.post('/open/poll/response',
    {
      pollId: pollId,
      response: response,
      usertoken: usertoken
    }
  )
