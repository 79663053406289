









import { Component, Prop, Vue } from 'vue-property-decorator'
import PollItem from '@/components/PollItem.vue'
import { PollDTO } from '@/components/model'
import { getPollRequests } from '@/services/open-poll-client'

@Component({
  components: { PollItem }
})
export default class PollLog extends Vue {
  @Prop()
  courseid: string

  polls: PollDTO[] = []

  get loading(): boolean {
    return !this.polls
  }

  private refreshPolls(): void {
    getPollRequests(this.courseid, localStorage.getItem('usertoken')).then(res => {
      this.polls = res.data
    })
  }

  mounted(): void {
    this.refreshPolls()
    setInterval(this.refreshPolls, 5000)
  }
}
